<template>
  <page-container :title="pageTitle">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner" style="margin: -35px -30px 10px -30px">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointnum" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="巡查点类型" prop="point_type" style="width: 25%">
              <a-select v-model="queryParams.point_type">
                <a-select-option value="0001">园区</a-select-option>
                <a-select-option value="0002">大堂</a-select-option>
                <a-select-option value="0003">楼层</a-select-option>
                <a-select-option value="0004">天面与避难层</a-select-option>
                <a-select-option value="0005">地下室</a-select-option>
                <a-select-option value="0006">商铺区</a-select-option>
                <a-select-option value="0007">活动场地</a-select-option>
                <a-select-option value="0008">设备区</a-select-option>
                <a-select-option value="0009">车厂出入口</a-select-option>
                <a-select-option value="0010">垃圾转运站</a-select-option>
                <a-select-option value="0011">其他</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="巡查点名称" prop="machineroom_name" style="width: 25%">
              <a-input v-model="queryParams.point_name"></a-input>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" style="margin-right: 10px" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
            <div v-show="showAdvanced" class="advanced-query">
              <a-form-model-item label="巡查点状态" prop="status" style="width: 25%">
                <a-select v-model="queryParams.status">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option value="0">使用中</a-select-option>
                  <a-select-option value="1">作废</a-select-option>
                </a-select>
              </a-form-model-item>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner" style="margin: -25px -30px 10px -30px">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <a-button @click="exportWordModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出标识卡</a-button>
            <a-button @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>
            <a-button @click="addRecord" style="margin-right: 10px" type="primary"><a-icon type="plus"></a-icon>新增</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :customRow="customRow" :data-source="tableData" row-key="point_id" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="create_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="point_type" slot-scope="value,record">{{pointTypes[value]}}</span>
          <span slot="point_qrcode" slot-scope="value,record"><a-button style="margin-left: -15px" type="link" @click="handlePreview(value)">{{record.point_name}}</a-button></span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.point_id">详情</a-menu-item>
                <a-menu-item :key="'edit-'+record.point_id">修改</a-menu-item>
                <a-menu-item :key="'delete-'+record.point_id">作废</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel" width="1080px">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
    <a-modal :visible.sync="qrcodeModalVisible" :width="250" :closable="false">
      <template slot="footer">
        <a-button @click="qrcodeModalVisible=false">关闭</a-button>
        <a-button type="primary" @click="downloadFile()">下载</a-button>
      </template>
      <a id="downloadQrcode"><div id="qrCode" ref="qrCodeDiv"></div></a>
    </a-modal>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :ActiveKey="activeKey" :detailData="modalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
  </page-container>
</template>
<script>
import moment from "moment";
import {mapGetters, mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getCache, getItemFromArrayByKey} from "U/index";
import deptselect from "@/mixins/deptselect";
import {
  deletePropertyInspectionPoint,
  exportPropertyInspectionPointExcel, exportPropertyInspectionPointIDCardWord,
  getPropertyInspectionPointListByCondition, modifyPropertyInspectionPoint
} from "A/patrol";
import addOrEditModal from "V/patrols/patrolsSetting/inspectionPoint/addOrEditModal";
import QRCode from "qrcodejs2";
import {pointTypes} from "@/json/patrol";
export default {
  name: "propertyInspectionPoint",
  mixins: [deptselect,pagination],
  components: {
    addOrEditModal,
  },
  data() {
    return {
      moment,
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      pointTypes,
      queryParams:{
        monitorpointnum:'',
        monitorpointname:'',
        point_type:'',
        point_num:'',
        point_name:'',
        point_qrcode:'',
        remarks:'',
        status:'0',
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '巡查点类型',
          dataIndex: 'point_type',
          key: 'point_type',
          ellipsis: true,
          scopedSlots: { customRender: 'point_type' },
        },
        {
          title: '巡查点编号',
          dataIndex: 'point_qrcode',
          key: 'point_qrcode',
          ellipsis: true,
          width: 320,
        },
        {
          title: '巡查点名称',
          dataIndex: 'point_name',
          key: 'point_name',
          ellipsis: true,
          scopedSlots: { customRender: 'point_name' },
        },
        // {
        //   title: '巡查点二维码',
        //   dataIndex: 'point_qrcode',
        //   key: 'point_qrcode',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'point_qrcode' },
        // },
        {
          title: '使用自有巡查点编号',
          dataIndex: 'has_own_id',
          key: 'has_own_id',
          ellipsis: true,
        },
        {
          title: '备注',
          dataIndex: 'remarks',
          key: 'remarks',
          ellipsis: true,
        },
        {
          title: '设置人',
          dataIndex: 'creator',
          key: 'creator',
          ellipsis: true,
        },
        {
          title: '设置时间',
          dataIndex: 'create_time',
          key: 'create_time',
          ellipsis: true,
          scopedSlots: { customRender: 'create_time' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'operation' },
          width: 70
        },
      ],
      userdepidCascaderSelected: [],
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      activeKey:'1',
      baseurl:'https://fda.u-lake.com/',
      previewImage: '',
      previewVisible:false,
      qrcode:'',
      qrcodeModalVisible:false,

      //操作按钮权限
      btnList:[],
      menu:{},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['operationMenuTree']),
    pageTitle() {
      return "巡查点档案设置"
    },
    breadcrumb() {
      const pages = [{name:"项目设置",path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    this.menu=getItemFromArrayByKey(this.operationMenuTree,"menuid",this.$route.params.menuid,"children")
    for(let i=0;i<this.menu.children.length;i++){
      this.btnList.push(this.menu.children[i].menuname)
    }
    this.getTableData()
    this.initDeptOptionsAll();
  },
  methods:{
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getPropertyInspectionPointListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.queryInstallDate = [];
      this.userdepidCascaderSelected=[]
      this.$refs.queryForm.resetFields();
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let point_id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'point_id', point_id);
      if(type == 'delete') {
        this.deleteConfirm(point_id, record)
      }else{
        this.showModal(type,record)
      }
    },
    addRecord(){
      this.modalVisible=true
      this.modalShowType='add'
    },
    exportModal(){
      this.$confirm('你确认要按照所选筛选条件导出文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.exportPropertyInspectionPoint()
      }).catch(()=>{
      });
    },
    exportPropertyInspectionPoint(){
      let params = {
        ...this.queryParams,
      }
      this.showLoading();
      exportPropertyInspectionPointExcel(params).then((res)=> {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(()=>{
        this.hideLoading();
      })
    },
    exportWordModal(){
      this.$confirm('你确认要按照所选筛选条件批量导出标识卡文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.batchExport()
      }).catch(()=>{
      });
    },
    batchExport() {
      let params = {...this.queryParams}
      this.showLoading();
      getPropertyInspectionPointListByCondition(params).then((res) => {
        this.hideLoading();
        if (res && res.returncode == "0") {
          for (let i = 0; i < res.item.length; i++) {
            this.exportPropertyInspectionPointIDCard(res.item[i])
          }
        }
      }).catch(() => {
        this.hideLoading();
      })
    },
    exportPropertyInspectionPointIDCard(record){
      let params={
        ...record
      }
      exportPropertyInspectionPointIDCardWord(params).then(res=>{
        if(res&&res.returncode=='0'){
          this.$message.success("操作成功")
        }
      })
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            this.modalDetailData=record
            this.modalShowType="detail"
            this.modalDetailData.btnList=this.btnList
            this.modalVisible=true
          },
        },
      }
    },
    showModal(type,record,activeKey){
      if(type=='detail'){
        this.modalDetailData=record
        this.modalShowType=type
        this.activeKey=activeKey
        this.modalDetailData.btnList=this.btnList
        this.modalVisible=true
      }else if(type=='edit'){
        this.modalDetailData=record
        this.modalShowType=type
        this.activeKey=activeKey
        this.modalDetailData.btnList=this.btnList
        this.modalVisible=true
      }
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.point_id);
      }).catch(()=>{

      }) ;
    },
    delete(point_id) {
      this.showLoading();
      if(point_id) {
        let params = {
          point_id,
        };
        deletePropertyInspectionPoint(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    deleteBatch() {
      if(this.batchrowids) {
        this.deleteBatchConfirm();
      }else {
        this.$message.warning('请先选择删除项');
      }
    },
    deleteBatchConfirm() {
      this.$confirm('确定要删除选中数据?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(this.batchrowids);
      }).catch(()=>{

      }) ;
    },
    rowSelectChange(selectedRowKeys, selectedRows) {
      this.batchrowids = selectedRowKeys.join('|');
    },
    async handlePreview(picUrl) {
      this.previewImage = this.baseurl+picUrl;
      this.previewVisible = true;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    qrcodeWatch(record){
      this.qrcodeModalVisible=true
      this.qrcode=record.equipment_name
      this.$nextTick(function () {
        this.bindQRCode(record);
      })
    },
    bindQRCode: function (record) {
      if(this.$refs.qrCodeDiv){
        this.$refs.qrCodeDiv.innerHTML = "" //清除上一个二维码
      }
      new QRCode(this.$refs.qrCodeDiv, {
        text: record.monitorpointnum + '-' + record.point_num,
        width: 200,
        height: 200,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
      })
    },
    downloadFile(fileName, content) {
      let qrCodeCanvas = document
          .getElementById("qrCode")
          .getElementsByTagName("canvas");
      let a = document.createElement("a");
      a.href = qrCodeCanvas[0].toDataURL("image/url");
      a.download = this.qrcode+`的二维码.png`;
      a.click();
    },
  },
}
</script>
<style scoped>

</style>
<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="1100" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">{{showType=='detail' ? '关闭' : '取消'}}</a-button>
        <a-button v-if="showType=='add' || showType=='edit'" type="primary" @click="confirm()">提交</a-button>
      </template>
      <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :model="formData" :rules="formRules" :label-col="{span:8}" :wrapper-col="{span:16}">
        <a-tabs type="card" v-model="activeKey">
          <a-tab-pane key="1" tab="巡查点信息"></a-tab-pane>
          <a-tab-pane key="2" tab="巡查记录" v-if="showType==='detail'"></a-tab-pane>
        </a-tabs>
        <div v-show="activeKey == '1'">
          <!-- =======================巡查点信息======================= -->
          <h3 style="font-size: large;font-weight: bold; color: #0388fd;">巡查点信息<a-button type="primary" @click="exportPropertyInspectionPointIDCard(formData)" style="float:right;margin-right: 20px">导出</a-button></h3>
          <a-form-model-item label="项目名称" prop="monitorpointnum">
            <a-select :disabled="showType=='detail'" v-model="formData.monitorpointnum" placeholder="请选择" show-search :filter-option="filterOption" >
              <a-select-option v-for="(item, index) in monitorpointList" :key="index" :value="item.monitorpointnum">{{item.monitorpointname}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="巡查点类型" prop="point_type">
            <a-select :disabled="showType=='detail'||showType==='edit'" v-model="formData.point_type">
              <a-select-option value="0001">园区</a-select-option>
              <a-select-option value="0002">大堂</a-select-option>
              <a-select-option value="0003">楼层</a-select-option>
              <a-select-option value="0004">天面与避难层</a-select-option>
              <a-select-option value="0005">地下室</a-select-option>
              <a-select-option value="0006">商铺区</a-select-option>
              <a-select-option value="0007">活动场地</a-select-option>
              <a-select-option value="0008">设备区</a-select-option>
              <a-select-option value="0009">车厂出入口</a-select-option>
              <a-select-option value="0010">垃圾转运站</a-select-option>
              <a-select-option value="0011">其他</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="巡查点名称" prop="point_name">
            <a-input :disabled="showType==='detail'" v-model.trim="formData.point_name" />
          </a-form-model-item>
          <a-form-model-item label="是否使用已有巡查点编号" prop="has_own_id">
            <a-select :disabled="showType==='detail'||showType==='edit'" v-model.trim="formData.has_own_id">
              <a-select-option value="是">是</a-select-option>
              <a-select-option value="否">否</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item v-if="formData.has_own_id==='否'&&showType!=='detail'" label="巡查点编号" prop="point_num">
            <a-input :disabled="showType==='detail'||showType==='edit'" v-model.trim="formData.point_num" placeholder="请输入5位的巡查点编号" :max-length="5"/>
          </a-form-model-item>
          <a-form-model-item v-if="formData.has_own_id==='否'&&showType==='detail'" label="巡查点编号" prop="point_qrcode">
            <a-input :disabled="showType==='detail'||showType==='edit'" placeholder="" v-model.trim="formData.point_qrcode" placeholder=""/>
          </a-form-model-item>
          <a-form-model-item v-if="formData.has_own_id==='是'" label="项目已有巡查点编号" prop="point_qrcode">
            <a-input :disabled="showType==='detail'||showType==='edit'" placeholder="" v-model.trim="formData.point_qrcode" placeholder="请输入已有的的巡查点编号"/>
          </a-form-model-item>
          <a-form-model-item v-if="showType==='detail'" label="创建人" prop="creator">
            <a-input :disabled="showType==='detail'" v-model.trim="formData.creator" />
          </a-form-model-item>
          <a-form-model-item label="关联摄像机名称/编号" prop="monitor_num">
            <a-input :disabled="true" v-model.trim="formData.monitor_num" />
          </a-form-model-item>
          <a-form-model-item label="备注" prop="remarks">
            <textarea :disabled="showType=='detail'" v-model.trim="formData.remarks" style="width: 305px"/>
          </a-form-model-item>
          <a-form-model-item label="巡查点二维码" prop="qrcode">
            <div id="qrCode" ref="qrCodeDiv"></div>
          </a-form-model-item>
        </div>
        <div v-show="activeKey == '2'">
          <h3 style="font-size: large;font-weight: bold; color: #0388fd;">巡查记录</h3>
          <div class="box-container" style="margin-top: 10px;">
            <div class="box-container-inner">
              <div class="simple-query">
                <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
                  <a-form-model-item label="查询时段" prop="queryInstallDate" style="width: 25%">
                    <a-range-picker v-model="queryInstallDate"></a-range-picker>
                  </a-form-model-item>
                  <a-form-model-item label="巡查人员" prop="username" style="width: 25%">
                    <a-select v-model="queryParams.username">
                      <a-select-option v-for="(item, index) in usernameList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item label="巡查结论" prop="result_code" style="width: 25%">
                    <a-select v-model="queryParams.result_code">
                      <a-select-option v-for="(item, index) in resultCodesList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <div class="query-btns" style="float: right;width: 24%">
                    <a-button @click="resetQueryParams">重置</a-button>
                    <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
<!--                    <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>-->
                  </div>
                  <div v-show="showAdvanced" class="advanced-query">
<!--                    <a-form-model-item label="巡查时长" prop="inspection_interval" style="width: 25%">-->
<!--                      <a-select v-model="queryParams.inspection_interval">-->
<!--                        <a-select-option value="0~30分钟">0~30分钟</a-select-option>-->
<!--                        <a-select-option value="30分钟~1小时">30分钟~1小时</a-select-option>-->
<!--                        <a-select-option value="1小时~3小时">1小时~3小时</a-select-option>-->
<!--                        <a-select-option value="3小时~1天">3小时~1天</a-select-option>-->
<!--                        <a-select-option value="大于1天">大于1天</a-select-option>-->
<!--                      </a-select>-->
<!--                    </a-form-model-item>-->
                  </div>
                </a-form-model>
              </div>
              <div class="box-container-inner">
                <div class="table-header">
                  <h3 class="table-title">巡查记录</h3>
                  <div class="table-btns">
                    <!--            <a-button type="primary" @click="showModal()"><a-icon type="plus"></a-icon>新建</a-button>-->
                    <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
                  </div>
                </div>
                <a-table :columns="inspectionTableColumns" :customRow="customRow" :data-source="tableData" row-key="point_id" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
                  <span slot="inspection_time" slot-scope="value, record">
                    <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
                      <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
                    </a-tooltip>
                  </span>
                  <span slot="result_code" slot-scope="value, record">
                    <span>{{value?resultCodes[value]:""}}</span>
                  </span>
                  <span slot="operation" slot-scope="value, record">
                    <a-dropdown>
                      <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                        <a-icon type="menu"/>
                      </a>
                      <a-menu slot="overlay" @click="operationClick">
                        <a-menu-item :key="'detail-'+record.point_id">详情</a-menu-item>
                        <!--                <a-menu-item :key="'edit-'+record.point_id">修改</a-menu-item>-->
                        <!--                <a-menu-item :key="'delete-'+record.point_id">修改</a-menu-item>-->
                      </a-menu>
                    </a-dropdown>
                  </span>
                </a-table>
                <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
                  <template slot="buildOptionText" slot-scope="props">
                    <span>{{ props.value }}条/页</span>
                  </template>
                </a-pagination>
              </div>
            </div>
          </div>
        </div>
      </a-form-model>
    </a-modal>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel" width="1080px">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
    <detail-modal :visible.sync="InspectionModalVisible" :detail-data="InspectionModalDetailData" @get-operation-result="getTableData"></detail-modal>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import {
  addPropertyInspectionPoint, exportPropertyInspectionPointIDCardWord, getPropertyInspectionPointInfoById,
  modifyPropertyInspectionPoint
} from "A/patrol";
import {getMonitorPointNameListByCondition} from "A/monitoring";
import {getCache, getItemFromArrayByKey, getTimeRange} from "U/index";
import QRCode from "qrcodejs2";
import {getPropertyInspectionListByCondition, getPropertyInspectionUsernameList} from "A/patrol";
import {resultCodes, resultCodesList} from "@/json/patrol";
import pagination from "@/mixins/pagination";
import DetailModal from "V/workCheck/patrol/patrolCheck/DetailModal";
export default {
  mixins: [areaselect, deptselect,pagination],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    },
    ActiveKey: {
      default: '1'
    },
  },
  components: {
    DetailModal,
  },
  data() {
    return {
      moment,
      size: 'default',
      modalBodyStyle: {
        height: '675px',
        overflowY: 'auto',
      },
      modalVisible: false,
      activeKey: '1',
      formData: {
        //巡查点信息
        point_id: '',
        monitorpointnum: '',
        monitorpointname: '',
        point_type: '',
        has_own_id: '否',
        point_num: '',
        point_name: '',
        point_qrcode: '',
        remarks: '',
        creat_time: '',
        creator: '',
        monitor_num: '',
        status: '0',
      },
      //这里面的数据属于必填项
      formRules: {
        monitorpointnum: [{required: true, message: '请选择项目名称'}],
        point_type: [{required: true, message: '请选择巡查点分类'}],
        point_num: [
          {required: true, message: '请输入巡查点编号'},
          {
            validator: (rule, value, callback) => {
              if(/^\d{5}$/.test(value)) {
                callback();
              }else {
                callback('巡查ID为5位数字，请检查是否填写正确')
              }
            }, trigger: 'blur'
          }
        ],
        point_name: [{required: true, message: '请输入巡查点名称'}],
        has_own_id: [{required: true, message: '请选择是否使用已有巡查点编号'}],
      },
      userdepidCascaderSelected: [],
      baseurl:'https://fda.u-lake.com/',
      previewImage: '',
      previewVisible:false,
      monitorpointList:[],
      showAdvanced: false,
      resultCodes,
      resultCodesList,
      usernameList:[],
      queryInstallDate: null,
      queryParams: {
        monitorpointname:'',
        point_name:'',
        username:'',
        result_code:'',
        inspection_interval:'',
        starttime:'',
        endtime:'',
      },
      inspectionTableColumns: [
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '巡查点名称',
          dataIndex: 'point_name',
          key: 'point_name',
          ellipsis: true,
        },
        {
          title: '巡查人员',
          dataIndex: 'username',
          key: 'username',
          ellipsis: true,
        },
        {
          title: '巡查时间',
          dataIndex: 'inspection_time',
          key: 'inspection_time',
          ellipsis: true,
          scopedSlots: { customRender: 'inspection_time' }
        },
        {
          title: '巡查时长',
          dataIndex: 'inspection_interval',
          key: 'inspection_interval',
          ellipsis: true,
        },
        {
          title: '巡查结论',
          dataIndex: 'result_code',
          key: 'result_code',
          ellipsis: true,
          scopedSlots: { customRender: 'result_code' }
        },
        {
          title: '签到地址',
          dataIndex: 'inspection_address',
          key: 'inspection_address',
          ellipsis: true,
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 70,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      InspectionModalVisible: false,
      InspectionModalShowType: '',
      InspectionModalDetailData: null,

      //操作按钮权限
      btnList:[],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['uploadHeaders', 'uploadFacilityEquipment']),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.activeKey=this.ActiveKey
        if(this.detailData){
          this.btnList=this.detailData.btnList
        }
        this.resetData();
        this.getMonitorPointNameList();
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetData();
        this.$emit('update:visible', false);
      }
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
    // 'formData.monitorpointnum':function(val) {
    //   if(val && val.length && this.formData.has_own_id!=='是') {
    //     this.formData.point_qrcode = this.formData.monitorpointnum+"~"+this.formData.point_type+"~"+this.formData.point_num
    //   }
    // },
    // 'formData.point_type':function(val) {
    //   if(val && val.length && this.formData.has_own_id!=='是') {
    //     this.formData.point_qrcode = this.formData.monitorpointnum+"~"+this.formData.point_type+"~"+this.formData.point_num
    //   }
    // },
    // 'formData.point_num':function(val) {
    //   if(val && val.length && this.formData.has_own_id!=='是') {
    //     this.formData.point_qrcode = this.formData.monitorpointnum+"~"+this.formData.point_type+"~"+this.formData.point_num
    //   }
    // },
    spareEquipmentList(val) {
      if(val && val.length) {
        this.formData.spareEquipIds = val.map(item => item.directories_id).join('|');
      }else {
        this.formData.spareEquipIds = '';
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
    this.getUsernameList();
    this.initDeptOptionsAll();
  },
  methods: {
    getMonitorPointNameList(){
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
    },
    resetData() {
      if (this.$refs.modalForm) {
        this.$refs.modalForm.resetFields();
      }
      this.formData.point_num=""
      this.formData.point_qrcode=""
      if(this.$refs.qrCodeDiv){
        this.$refs.qrCodeDiv.innerHTML = "" //清除上一个二维码
      }
    },
    initDetail() {
      if (this.detailData && this.detailData.point_id && (this.showType == 'edit' || this.showType == 'detail')) {
        this.$nextTick(() => {
          let params = {
            point_id: this.detailData.point_id
          }
          getPropertyInspectionPointInfoById(params).then(res => {
            if (res && res.returncode == '0') {
              this.formData = res.item;
              // 特别数据回显
              if (this.formData.point_qrcode) {
                let nameIndex = this.formData.point_qrcode.lastIndexOf('/');
                if (nameIndex < 0) {
                  nameIndex = this.formData.point_qrcode.lastIndexOf('\\');
                }
                let fileName = this.formData.point_qrcode.substr(nameIndex + 1);
                this.machineRoomPicList = [{
                  uid: 'point_qrcodeid',
                  name: fileName,
                  status: 'done',
                  url: this.formData.point_qrcode,
                }];
              }
              this.$nextTick(function () {
                this.bindQRCode();
              })
              this.getTableData(true);
            }
          })
        })
      }
    },
    confirm() {
      if (this.showType === 'add' || this.showType === 'edit') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            if(this.formData.has_own_id==="否"){
              this.formData.point_qrcode=this.formData.monitorpointnum+"-00000"+this.formData.point_type+"-"+this.formData.point_num
            }
            this.formData.creator=this.userInfo.username
            let params = {
              ...this.formData,
            };
            if (this.showType === 'add') {
              this.showLoading();
              addPropertyInspectionPoint(params).then(res => {
                this.hideLoading();
                if (res && res.returncode === '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else if(this.showType === 'edit'){
              this.showLoading();
              modifyPropertyInspectionPoint(params).then(res => {
                this.hideLoading();
                if (res && res.returncode === '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            }else{
              this.$message.warning('请确认信息填写完整')
            }
          } else {
            this.$message.warning('请确认信息填写完整')
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    machineRoomPicUpload(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.urlPath;
          this.formData.point_pic = file.response.urlPath;
        }
        return file;
      });
      this.machineRoomPicList = fileList;
    },
    async handlePreview() {
      this.previewImage = this.baseurl+this.formData.point_qrcode;
      this.previewVisible = true;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    bindQRCode: function () {
      if(this.$refs.qrCodeDiv){
        this.$refs.qrCodeDiv.innerHTML = "" //清除上一个二维码
      }
      new QRCode(this.$refs.qrCodeDiv, {
        text: this.formData.has_own_id==="是"?this.formData.point_qrcode:this.formData.monitorpointnum+"-00000"+this.formData.point_type+"-"+this.formData.point_num,
        width: 150,
        height: 150,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
      })
    },
    getUsernameList(){
      getPropertyInspectionUsernameList({}).then(res=>{
        if(res&&res.returncode==='0'){
          this.usernameList=res.item
        }
      })
    },
    resetQueryParams() {
      this.userdepidCascaderSelected=[];
      this.queryInstallDate=[];
      this.$refs.queryForm.resetFields();
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            this.InspectionModalDetailData=record
            this.InspectionModalShowType='detail'
            this.InspectionModalVisible=true
          },
        },
      }
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        monitorpointnum:this.formData.monitorpointnum,
        point_name:this.formData.point_name,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getPropertyInspectionListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let point_id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'point_id', point_id);
      if(type == 'detail') {
        this.InspectionModalDetailData=record
        this.InspectionModalShowType='detail'
        this.InspectionModalVisible=true
      }
    },
    exportPropertyInspectionPointIDCard(record){
      let params={
        ...record
      }
      exportPropertyInspectionPointIDCardWord(params).then(res=>{
        if(res){
          this.$message.success("导出成功")
        }
      })
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  }
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
</style>